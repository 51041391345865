import Iuser from '@/Components/DTO/Iuser';

// import TopExperts from '@/Components/Home/TopExperts';
// import TopExpertsLocation from '@/Components/Home/TopExpertsLocation';
// import TrendingLocalities from '@/Components/Home/TrendingLocalities';
// import AreaSpecialistRequirementLocation from '@/Components/Pages/Profile/AreaSpecialistRequirementLocation';
// import AreaSpecialistSalesLocation from '@/Components/Pages/Profile/AreaSpecialistSalesLocation';
// import CustomMetatags from '@/Components/SEO/CustomMetaTags';
// import HomeSearchLoggedIn from '@/Components/SearchComponents/HomeSearch/HomeSearchLoggedIn';
import Servererror from '@/Components/ServerMaintenance';
import { MobileContext } from '@/utils/DetectMobileContext';
import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { authOptions } from './api/auth/[...nextauth]';

// const HomeSearch = dynamic(
//   () => import('@/Components/SearchComponents/HomeSearch/HomeSearch')
// );
const HomeSearchLoggedIn = dynamic(
  () => import('@/Components/SearchComponents/HomeSearch/HomeSearchLoggedIn')
);
const TopExperts = dynamic(() => import('@/Components/Home/TopExperts'));
// const TopExpertsLocation = dynamic(
//   () => import('@/Components/Home/TopExpertsLocation')
// );
// const TrendingLocalities = dynamic(
//   () => import('@/Components/Home/TrendingLocalities')
// );
const AreaSpecialistRequirementLocation = dynamic(
  () => import('@/Components/Pages/Profile/AreaSpecialistRequirementLocation')
);
const AreaSpecialistSalesLocation = dynamic(
  () => import('@/Components/Pages/Profile/AreaSpecialistSalesLocation')
);
const CustomMetatags = dynamic(() => import('@/Components/SEO/CustomMetaTags'));
const HighEndHome = dynamic(() => import('@/Components/Home/HighEndHome'));
// const HighEndHomeLocation = dynamic(
//   () => import('@/Components/Home/HighEndHomeLocation')
// );
const HomeMarketPerformance = dynamic(
  () => import('@/Components/Home/HomeMarketPerformance')
);
// const TopDevelopers = dynamic(() => import('@/Components/Home/TopDevelopers'));

export default function Home({ user }: { user: Iuser }) {
  const { isMobile } = useContext(MobileContext);

  const router = useRouter();

  return <Servererror />;
  // return user ? (
  //   <div className="indexPage">
  //     <CustomMetatags
  //       curl={`https://brokerterminal.com`}
  //       title={`Broker Terminal: Invest in Dubai Real Estate with our Expert Tools`}
  //       keywords={`Property search dubai, Flat for sale in dubai, Buy villa in dubai, Buy apartment in dubai, Townhouse for sale in dubai`}
  //       canonical={`https://brokerterminal.com`}
  //       description={`Buy, Sell or Rent Property in Dubai, Use Our Analysis to Deal Smartly, Compare Prices, Return on Investme nt, Locations & Top Develope rs in Dubai.`}
  //     />
  //     {user && isMobile && (
  //       <div className={styles.lSearchContainer_greetings}>
  //         <Avatar src={user.profilePic || '.'} alt={user.firstName} />
  //         <div className={styles.lSearchContainer_greetings_texts}>
  //           <span className={styles.lSearchContainer_greetings_texts_head}>
  //             Welcome <span>{user.firstName}</span>
  //           </span>
  //           {/* <span className={styles.lSearchContainer_greetings_texts_desc}>
  //             Welcome to Broker Terminal
  //           </span> */}
  //         </div>
  //       </div>
  //     )}
  //     <HomeSearchLoggedIn />

  //     {user && !isMobile && (
  //       <div className={styles.lSearchContainer_greetings}>
  //         <Avatar src={user.profilePic || '.'} alt={user.firstName} />
  //         <div className={styles.lSearchContainer_greetings_texts}>
  //           <span className={styles.lSearchContainer_greetings_texts_head}>
  //             Welcome <span>{user.firstName}</span>
  //           </span>
  //           {/* <span className={styles.lSearchContainer_greetings_texts_desc}>
  //             Welcome to Broker Terminal
  //           </span> */}
  //         </div>
  //       </div>
  //     )}
  //     <AreaSpecialistSalesLocation />

  //     <AreaSpecialistRequirementLocation />
  //     <br />
  //     {/* <TopExperts /> */}
  //     <HighEndHome />

  //     {/* <OtherMembers /> */}
  //   </div>
  // ) : (
  //   <div className="indexPage">
  //     <CustomMetatags
  //       title={`Broker Terminal: Invest in Dubai Real Estate with our Expert Tools`}
  //       keywords={`Property search dubai, Flat for sale in dubai, Buy villa in dubai, Buy apartment in dubai, Townhouse for sale in dubai`}
  //       canonical={`https://brokerterminal.com`}
  //       curl={`https://brokerterminal.com`}
  //       description={`Buy, Sell or Rent Property in Dubai, Use Our Analysis to Deal Smartly, Compare Prices, Return on Investment, Locations & Top Developers in Dubai.`}
  //     />
  //     <HomeSearch />
  //     <HomeMarketPerformance />
  //     {/* <TopExperts /> */}
  //     <HotLocations />
  //     {/* <HotProjects /> */}
  //     <HotDevelopers />
  //     {/* <TrendingLocalities /> */}
  //     <HighEndHome />
  //     {/* <TopDevelopers /> */}
  //     {/* <GoldenVisaEligibleSeg /> */}
  //     {/* <MoveInBox /> */}
  //     {/* <CompaniesListings /> */}
  //     {/* <BHKChoice /> */}
  //     {/* <LatestNews /> */}
  //     {/* {isMobile && <VideoPlayerHome />} */}
  //   </div>
  // );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);

  return {
    props: { user: session && session.user ? session.user : '' },
  };
}
