import TerminalButton from '@/Components/Buttons/TerminalButton';
import VideoPlayer from '@/Components/Modals/VideoPlayer';
import styles from '@/styles/Main/Home/Home.module.scss';
import { MobileContext } from '@/utils/DetectMobileContext';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
const Servererror = () => {
  const router = useRouter();
  const [videoPlay, setvideoPlay] = useState(false);
  const { isMobile } = useContext(MobileContext);
  return (
    // <div className={styles.mainHomecontainer}>
    //   <MainHeader />
    //   <MainContainer>
    <div className={styles.messageContainer}>
      <div className={styles.messageContainer_left}>
        <h1>Under Maintenance!</h1>
        <p>
          We apologize for the hiccup in our real estate journey. At Broker
          Terminal, we strive to provide a seamless experience, but even the
          best of us can encounter a roadblock. <br />
          <br />
          Rest assured, our team is working diligently behind the scenes to
          restore everything to its full glory. We believe that true happiness
          begins with finding the perfect home, and we won’t stop until we’ve
          created that joy for you. <br />
          <br />
          Thank you for your patience, and we’ll have everything back on track
          in no time!
        </p>
        <TerminalButton
          label="Back to Home"
          onClick={() => router.push('/')}
          width={isMobile ? '90%' : 'inhetr'}
          position={isMobile ? 'abs' : ''}
        />
      </div>
      <div className={styles.messageContainer_right}>
        <Image
          src={'/assets/Images/500/img.svg'}
          alt="404"
          width={350}
          height={600}
        />
        <Image
          src={'/assets/icons/videowatch.svg'}
          alt="watch"
          width={100}
          onClick={() => setvideoPlay(true)}
          height={100}
        />
      </div>{' '}
      {videoPlay && <VideoPlayer setPlay={setvideoPlay} play={videoPlay} />}
    </div>
    //   </MainContainer>

    // </div>
  );
};

export default Servererror;
