import styles from '@/styles/Modals/videoplayer.module.scss';

import { Close } from '@mui/icons-material';
import { ClickAwayListener } from '@mui/material';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import('react-player/youtube'), {
  ssr: false,
});
interface videoPlayerDto {
  play: boolean;
  setPlay: (plays: boolean) => void;
}
const VideoPlayer = ({ play = true, setPlay }: videoPlayerDto) => {
  const handleClickAway = () => {
    setPlay(false);
  };
  return (
    <div className={styles.videoPlayerContainer}>
      <div
        className={styles.videoPlayerContainer_cross}
        onClick={() => setPlay(false)}
      >
        <Close />
      </div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.videoPlayerContainer_video}>
          <ReactPlayer
            playing={play}
            loop
            controls
            width="100%"
            height="100%"
            url="https://www.youtube.com/playlist?list=PLU-RB9Q0U085Npwt74lKL2QOEirgvf-cT"
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default VideoPlayer;
